<template>
	<div class="col-md-7 col-sm-7 mt-10 boxmain" v-if="my_data==null">
		<SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxrouting">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                   <Skeleton/>
                </h2>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">


              <div class="col-lg-6 col-md-6 col-sm-12" v-for="k in 6" :key="k">
                <div class="price-table mrb-30 text-center">
                  <div class="table-header">

                    <h4 class="pricing-plan-name">
                    	<Skeleton/>
                    </h4>
                  </div>
                  <div class="table-content">
                    <ul class="list-items">
                      <li>
                        <i class="fa fa-check mrr-10 text-primary-color"></i>
                       		<Skeleton/>
                      </li>
                      <li>
                        <i class="fa fa-check mrr-10 text-primary-color"></i>
                       		<Skeleton/>
                      </li>
                      <li>
                        <i class="fa fa-check mrr-10 text-primary-color"></i>
                       			<Skeleton/>
                      </li>
                    </ul>
                  </div>
                  <div class="table-footer table-footer1">
                   <Skeleton/>
                  </div>
                </div>
              </div>
              
              
              

            </div>
          </div>

          
         </SkeletonTheme>
     </div>


    <div class="col-md-7 col-sm-7 mt-10 boxmain" v-else>
          <div class="container boxrouting">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('booth.title')}} {{area}} 
                  		<span v-if="my_data.length">( {{$t('booth.booth',{booth:my_data.length})}} )</span>
                </h2>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">


              <div class="col-lg-6 col-md-6 col-sm-12" v-for="(v,k) in my_data" :key="k">
                <div class="price-table mrb-30 text-center">
                  <div class="table-header">

                    <h4 class="pricing-plan-name">
                    	{{v.boothName}}
                    </h4>
                  </div>
                  <div class="table-content">
                    <ul class="list-items">
                      <li v-if="v.width">
                        <i class="fa fa-check mrr-10 text-primary-color"></i>
                        {{$t('booth.width')}}: {{v.width}}m
                      </li>
                      <li v-if="v.height">
                        <i class="fa fa-check mrr-10 text-primary-color"></i>
                        {{$t('booth.height')}}: {{v.height}} m
                      </li>
                      <li v-if='v.acreage'>
                        <i class="fa fa-check mrr-10 text-primary-color"></i>
                        {{$t('booth.area')}} {{decimalAdjust('round',v.acreage,-2)}} m2
                      </li>
                    </ul>
                  </div>
                  <div class="table-footer table-footer1">
                    <a href="javascript:;" data-toggle="modal" :data-target="'#exampleModalLong'+k" class="animate-btn">
                    	{{$t('booth.see')}}
                    </a>
                  </div>
                </div>

                <div class="modal fade bd-example-modal-lg content_area" :id="'exampleModalLong'+k" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
			            <div class="modal-dialog modal-xs">
			                <div class="modal-content">
			                  <div class="modal-header">
			                    <h5 class="modal-title">
			                     {{v.boothName}} 
			                    </h5>
			                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
			                      <span aria-hidden="true">&times;</span>
			                    </button>
			                  </div>
			                  <div class="modal-body popupposition">
			                    <p>
			                      <img :src="myurl+''+v.picture" />
			                    </p>
			                    <ul>
			                      <li v-if="v.width">
				                        <i class="fa fa-check mrr-10 text-primary-color"></i>
				                        {{$t('booth.width')}}: {{v.width}}m
				                      </li>
				                      <li v-if="v.height">
				                        <i class="fa fa-check mrr-10 text-primary-color"></i>
				                        {{$t('booth.height')}}: {{v.height}} m
				                      </li>
				                      <li v-if='v.acreage'>
				                        <i class="fa fa-check mrr-10 text-primary-color"></i>
				                        {{$t('booth.area')}} {{decimalAdjust('round',v.acreage,-2)}}  m2 
				                      </li>
			                    </ul>
			                    <div class="statusposition">
			                      <i class="fas fa-arrow-right"></i> 
			                      		{{$t('booth.status')}}:
			                      			 <span> 
			                      			 	 {{v.descriptionStatus}}
			                      			 	 <b style="color: #0199f3;"> 

			                      			 	 	(  <a href="tel:028.35116858"> 028.35116858)</a></b>
			                      			  </span>
			                      			 
			                    </div>

                          <div class="statusposition" v-if="v.flagCustomer=='1'">
                            <i class="fas fa-arrow-right"></i> 
                                 Tên Khách hàng :   <b style="color: #0199f3;">  {{v.content}} </b>
                                  
                          </div>

                          <div class="statusposition" v-if="v.price!=null">
                            <i class="fas fa-arrow-right"></i> 
                                 Giá Thuê:   <b style="color: #0199f3;">  {{v.price | toCurrency}} </b>
                                  
                          </div>

                          <div class="statusposition" v-if="v.contact!=null">
                            <i class="fas fa-arrow-right"></i> 
                                 Liên Hệ :   <b style="color: #0199f3;">  {{v.contact}} </b>
                                  
                          </div>

                          <div class="statusposition" v-if="v.leaseProcedure!=null">
                            <i class="fas fa-arrow-right"></i> 
                                 Thủ tục Thuê :   <b style="color: #0199f3;">  {{v.leaseProcedure}} </b>
                                  
                          </div>

			                  </div>
			                  <div class="modal-footer">
			                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
			                    		{{$t('booth.close')}}
			                    </button>
			                  
			                  </div>        
			                </div>
			            </div>
			          </div>


              </div>

              
              
            </div>
          </div>

          

        </div>

</template>


<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

  import axios from 'axios';
export default {
  
 
  data() {
    return {
      
      is_show:false,
      myurl:window.my_url,
      my_data:null,
      area:""
      

      
    };
  },

  components: {
    Skeleton,
    SkeletonTheme
    
  },
  created(){
  			this.area=this.$route.params.slug;
            axios.get(window.my_api+"api/booth/get-booths?language="+this.$i18n.locale,
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
              .then( (res)=>{
                  
                  if(res.status==200){
                       if(this.$route.query.t){
                          this.my_data=res.data.data.filter( n=> (n.type==1 &&  n.area == this.$route.params.slug) );
                            console.log("LKSDF");
                       }else{
                          this.my_data=res.data.data.filter( n=> (n.type!=1 && n.area == this.$route.params.slug) );
                       }
                       
                  	
                  		console.log(this.my_data);
                                  	          
                  }else {
                    console.log("ERROR",res);
                  }
                   

                });

              
          
      
  },
    filters: {
    
    toCurrency:function (value){
        if (typeof value !== "number") {
          return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
           
            maximumSignificantDigits:3
        });
        return formatter.format(value);
    }
  },
  methods:{
  	showContent(){
      this.is_show= !this.is_show;
    },
     decimalAdjust(type, value, exp) {
            // If the exp is undefined or zero...
            if (typeof exp === 'undefined' || +exp === 0) {
              return Math[type](value);
            }
            value = +value;
            exp = +exp;
            // If the value is not a number or the exp is not an integer...
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
              return NaN;
            }
            // Shift
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            // Shift back
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
      }
  }
}
</script>